<template>
  <div>
    <v-progress-linear
      v-show="store.userLoading || configLoading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-alert
      v-show="!isAuthenticated && !(store.userLoading || configLoading)"
      type="info"
      text
      class="mb-4 text-left"
      :value="true"
    >
      <div>
        <nuxt-link :to="{ name: 'user-account' }">
          <strong>Sign in</strong>
        </nuxt-link>
        to see the pricing calculator
      </div>
    </v-alert>

    <div v-show="isAuthenticated && !(store.userLoading || configLoading)">
      <v-slider
        class="mt-7"
        :disabled="pricingSliderLoading"
        show-ticks="always"
        :max="3"
        step="1"
        tick-size="4"
        v-model="pricingSlider"
        :loading="pricingSliderLoading"
        :ticks="pricingLabels"
      >
      </v-slider>
      <div class="mt-4">
        for
        {{ numberFilter(pricings[pricingSlider], '0,0') }}
        analysis in a billing period (one month) your price is
        <strong class="text-primary">
          {{
            currencyFilter(pricePreview, '$', 2, {
              spaceBetweenAmountAndSymbol: false,
            })
          }}
        </strong>
        per month
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from '@/composables/store'
const store = useStore()

const nuxtApp = useNuxtApp()
const numberFilter = nuxtApp.$numberFilter
const currencyFilter = nuxtApp.$currencyFilter

const configLoading = ref(false)
const pricingSlider = ref(0)
const pricingSliderLoading = ref(false)
const prices = ref([])
const pricePreview = ref(0)
const pricePreviewRequest = ref(null)

const pricings = [10, 500, 5000, 50000]

const isAuthenticated = computed(() => store.user !== null)

const pricingLabels = pricings.reduce((acc, curr, index) => {
  acc[index] = numberFilter(curr, '0,0')
  return acc
}, {})

const configRequest = useFetch('/api/v2/customer-portal/config', {
  server: false,
  credentials: 'include',
})

watchEffect(() => {
  if (isAuthenticated.value) {
    if (!configRequest.pending.value) {
      prices.value = configRequest?.data?.value?.prices
    }
  }
})

watchEffect(() => {
  if (pricePreviewRequest.value?.pending?.value === false) {
    pricingSliderLoading.value = false
    pricePreview.value = pricePreviewRequest.value?.data?.value?.totalPrice
  }
})

function fetchPrice(value) {
  pricingSliderLoading.value = true

  const priceId = prices.value[0].id
  const lookupKey = prices.value[0].lookup_key
  const usageCount = parseInt(pricings[value])

  pricePreviewRequest.value = useFetch(
    '/api/v2/customer-portal/price-preview',
    {
      method: 'POST',
      body: JSON.stringify({
        priceId,
        lookupKey,
        usageCount,
      }),
      credentials: 'include',
    }
  )
}

watch(
  () => pricingSlider.value,
  async (val) => {
    if (typeof val === 'number') {
      fetchPrice(val)
    }
  }
)
</script>